<template>
  <div> {{ vaccin }} </div>
</template>
  
  <script>
  import { mapGetters } from 'vuex'
  export default {
      props: {object: {type: Object, required: true}},
      data(){
          return  {
  
          }
      },
      methods: {
          
      },
      computed: {
        ...mapGetters({
          vaccins: 'sanitaire/vaccins'
        }),
        vaccin(){
          let v = this.vaccins.find(item => item.uid === this.object.vaccin)
          if(v) return v.libelle+ '('+ v.valence+')'
          return '-'
        }
      }
  
  
  }
  </script>
  
  <style lang="scss" scoped>
  .actions{
      a{
          margin-left: 5px;
      }
  }
  </style>